import { MatDialogRef } from '@angular/material/dialog';
import { WalletService } from './../../../../core/service/wallet.service';
import { Plan } from './../../../../core/entities/plan';
import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-plan-select-dialog',
  templateUrl: './plan-select-dialog.component.html',
  styleUrls: ['./plan-select-dialog.component.scss'],
})
export class PlanSelectDialogComponent implements AfterViewInit {
  plans: Plan[] = [];
  selectedPlanId = '';

  constructor(
    private readonly walletService: WalletService,
    private readonly dialogRef: MatDialogRef<PlanSelectDialogComponent>
  ) {}

  ngAfterViewInit(): void {
    this.walletService.getPlans(1, 10000000).subscribe({
      next: (response) => {
        this.plans = response.items;
      },
    });
  }

  close(): void {
    const plan = this.plans.find(
      (p) => p.id.toString() === this.selectedPlanId
    );

    this.dialogRef.close(plan);
  }
}
