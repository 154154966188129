import { MasterWallet } from './../entities/master-wallet';
import { WithdrawRequestStatusEnum } from './../enums/withdraw-request-enum';
import { UserStatusEnum } from './../enums/user-status-enum';
import { WithdrawRequest } from './../entities/withdraw-request';
import { PaginatedHttpResponse } from '@app/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import {
  WalletTransaction,
  MasterWalletTransaction,
  Plan,
} from '@app/entities';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  constructor(private readonly apiService: ApiService) {}

  getMasterWallet(): Observable<MasterWallet> {
    return this.apiService.get('panel/master-wallet');
  }

  getWithdrawRequests(
    page: number,
    limit: number,
    userId?: number,
    status?: UserStatusEnum
  ): Observable<PaginatedHttpResponse<WithdrawRequest>> {
    return this.apiService.get('panel/withdraw-request', {
      params: {
        page,
        limit,
        userId,
        status,
      },
    });
  }

  getWalletTransactions(
    page: number,
    limit: number,
    userId?: number
  ): Observable<PaginatedHttpResponse<WalletTransaction>> {
    return this.apiService.get('panel/wallet-transactions', {
      params: {
        page,
        limit,
        userId,
      },
    });
  }

  getMasterWalletTransactions(
    page: number,
    limit: number
  ): Observable<PaginatedHttpResponse<MasterWalletTransaction>> {
    return this.apiService.get('panel/master-wallet-transactions', {
      params: {
        page,
        limit,
      },
    });
  }

  updatedWithdrawRequest(
    withdrawRequestId: number,
    status: WithdrawRequestStatusEnum,
    adminResponse?: string,
    file?: File
  ): Observable<void> {
    const formData = new FormData();
    formData.append('withdrawRequestId', withdrawRequestId.toString());
    formData.append('status', status);
    if (adminResponse) {
      formData.append('adminResponse', adminResponse);
    }

    if (file) {
      formData.append('file', file);
    }

    return this.apiService.put('panel/withdraw-request', formData);
  }

  getPlans(
    page: number,
    limit: number,
    id?: string
  ): Observable<PaginatedHttpResponse<Plan>> {
    return this.apiService.get('money/plans', {
      params: {
        page,
        limit,
        id,
      },
    });
  }

  createPlan(plan: Plan): Observable<Plan> {
    return this.apiService.post('panel/plan', { ...plan });
  }

  updatePlan(plan: Plan): Observable<Plan> {
    return this.apiService.patch('panel/plan', { ...plan });
  }
}
