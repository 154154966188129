<h1 mat-dialog-title>Selecionar plano</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="fill">
        <mat-label>Plano</mat-label>
        <select matNativeControl required [(ngModel)]="selectedPlanId">
          <option value="">Selecione</option>
          <option [value]="plan.id" *ngFor="let plan of plans">
            {{ plan.name }}
          </option>
        </select>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="warn">Fechar</button>
  <button
    mat-raised-button
    mat-dialog-close
    color="primary"
    (click)="close()"
    [disabled]="!selectedPlanId"
  >
    Salvar
  </button>
</div>
